import { useEffect, useRef } from "react";

import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import Image from "./Image";
import LottieOnEntered from "./LottieOnEntered";
import linesLeft from "../assets/HP_Lines_join_left.json";
import linesRight from "../assets/HP_Lines_join_right.json";
import { mapValues } from "../utils/utils";

function ImageBackgroundAndLines({ image, backgroundColor, children }) {
  const imageContainerRef = useRef();
  const loopRef = useRef();
  const rafRef = useRef();
  const scaleWithInertiaRef = useRef();

  loopRef.current = () => {
    if (imageContainerRef.current) {
      const { height, top } = imageContainerRef.current.getBoundingClientRect();
      const halfWay = window.innerHeight / 2;
      const scale = mapValues(top, height, halfWay, 0.9, 1);

      if (scaleWithInertiaRef.current == null) {
        scaleWithInertiaRef.current = scale;
      } else {
        scaleWithInertiaRef.current +=
          (scale - scaleWithInertiaRef.current) / 5;
      }

      imageContainerRef.current.style.transform = `scale3d(${scaleWithInertiaRef.current},${scaleWithInertiaRef.current},1)`;
    }

    rafRef.current = requestAnimationFrame(loopRef.current);
  };

  useEffect(() => {
    rafRef.current = requestAnimationFrame(loopRef.current);

    return () => {
      cancelAnimationFrame(rafRef.current);
    };
  }, []);

  return (
    <Box backgroundColor={backgroundColor} position="relative">
      <Box
        pb={[0, null, 60, null]}
        px={[0, null, 70, null]}
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        zIndex={3}
      >
        <BoxWithGutter
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {children}
        </BoxWithGutter>
      </Box>

      <Box
        position="absolute"
        height="100%"
        width="100%"
        top={0}
        left={0}
        zIndex={2}
        display={["none", null, "flex", null]}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <LottieOnEntered animData={linesLeft} threshold={0.5} />
        <LottieOnEntered animData={linesRight} threshold={0.5} delay={1300} />
      </Box>
      <Box pb={[0, null, 60, null]} px={[0, null, 70, null]} zIndex={1}>
        <Box
          position="relative"
          height={0}
          width="100%"
          pb={["130%", "56.25%", null, null]}
        >
          <Box
            ref={imageContainerRef}
            position="absolute"
            top={0}
            left={0}
            height="100%"
            width="100%"
            opacity={0.7}
          >
            <Image
              src={image.src}
              alt={image.alt}
              objectFit="cover"
              position="absolute"
              top={0}
              left={0}
              height="100%"
              width="100%"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ImageBackgroundAndLines;
